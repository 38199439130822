import React from 'react'
// import NavbarMobile from './NavbarMobile'
import { compose, defaultProps, withStateHandlers, lifecycle, setDisplayName, fromRenderProps } from 'recompose'
// import Logo from 'components/Logo'
import styled from 'styled-components'
import HeaderNavbar from './HeaderNavbar'
import { Location } from '@reach/router'
import { GatsbyImage } from "gatsby-plugin-image";
import logoImg from '../../images/icon/logo.png'

const LogoWrapper = styled.div`
  width: 150px;

  a {
    display: block;
  }
`

const NavbarMobile = styled(HeaderNavbar)`
  position: absolute;
  top: 100%;
  z-index: 100;
  width: calc(100vw - 2rem);
  margin-left: 1rem;
  margin-right: 1rem;
`

const HeaderMobile = compose(
  setDisplayName(`HeaderMobile`),
  // enhanceWithPageContext,
  defaultProps({
    navigation: [],
    logoSrc: logoImg,
  }),
  fromRenderProps(Location, props => props),
  withStateHandlers(
    {
      navPopupOpen: false,
    },
    {
      toggleNavPopup: ({ navPopupOpen }) => (trueOrFalse = null) => ({
        navPopupOpen: trueOrFalse !== null ? trueOrFalse : !navPopupOpen,
      }),
    }
  ),

  lifecycle({
    componentDidUpdate ({ location: prevLocation }) {
      const { location } = this.props

      if (location !== prevLocation) {
        if (this.props.navPopupOpen) {
          this.props.toggleNavPopup && this.props.toggleNavPopup()
        }
      }
    }
  })
)(
  ({ logoFluid, navigation, navPopupOpen, toggleNavPopup }) => (
    <div className="header-mobile js-header-mobile d-block d-lg-none">
      <div className="header-mobile__bar">
        <div className="container-fluid clearfix">
          <button
            className={`hamburger hamburger--slider float-right ${ navPopupOpen ? `is-active` : `` }`}
            type="button"
            onClick={() => toggleNavPopup()}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>

          <LogoWrapper>
            <a className="logo" href="#">
              <GatsbyImage image={logoFluid} />
            </a>
          </LogoWrapper>
        </div>
      </div>

      <NavbarMobile items={navigation} className="navbar-mobile" listClassName="navbar-mobile__list" style={{ display: navPopupOpen ? `block` : `none` }} onLinkClick={toggleNavPopup} />
    </div>
  )
)

export default HeaderMobile
