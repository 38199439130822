import React from 'react'
import Icon from 'components/SvgSpriteIcon'

const HeaderTopContact = React.forwardRef(
  ({ address, phone, facebookUrl }, setRef) => (
    <section className="top-contact" ref={setRef}>
      <div className="container">
        <div className="top-contact-inner">
          <div className="top-contact__left">
            {address && <div className="top-contact__item">
              <Icon id="lnr-map" className="mr-1" />
              {address}
            </div>}
            {phone && <div className="top-contact__item">
              <Icon id="lnr-phone-handset" className="mr-1" />
              {phone}
            </div>}
          </div>
          <div className="top-contact__right">
            <ul className="list-unstyled list-inline list-social mb-0">
              {facebookUrl && <li className="list-inline-item">
                <a className="ic-fb" rel="noopener"target="_blank" href={facebookUrl}>
                  <Icon id="zmdi-facebook-box" size="1.125rem" />
                </a>
              </li>}
              {/* <li className="list-inline-item">
                <a className="ic-twi" href="#">
                  <i className="zmdi zmdi-twitter" />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="ic-insta" href="#">
                  <i className="zmdi zmdi-instagram" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
)

export default HeaderTopContact
