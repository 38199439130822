/* global $, jQuery */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { lifecycle, withContext, getContext } from 'recompose'
import { injectIntl } from 'react-intl'

const PageDataShape = {
  pageData: PropTypes.object,
}

export const enhanceWithPageDataProvider = withContext(
  PageDataShape,
  ({ data: pageData }) => ({ pageData }),
)

export const enhanceWithPageData = getContext(PageDataShape)

const PageContextShape = {
  pageContext: PropTypes.object,
}

export const enhanceWithPageContextProvider = withContext(
  PageContextShape,
  ({ pageContext }) => ({ pageContext }),
)

export const enhanceWithPageContext = getContext(PageContextShape)

export const withIntl = Component => {
  class Wrapper extends React.Component {
    render () {
      const { innerRef, ...props } = this.props
      return <Component ref={innerRef} {...props} />
    }
  }

  const IntlWrapper = injectIntl(Wrapper)
  return forwardRef((props, ref) => <IntlWrapper {...props} innerRef={ref} />)
}

export const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export const enhanceWithMountedState = lifecycle({
  componentDidMount () {
    this.setState({ mounted: true })
  },
})

export const Mounted = enhanceWithMountedState(({ mounted, children }) => <>{(mounted && children) || null}</>)

export const defaultOnFail = (getter, def) => {
  try {
    return getter.call()
  } catch (e) { }

  return def
}
