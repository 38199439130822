import React from 'react'

const Copyright = () => (
  <section className="section copyright">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="copyright-wrap border-0 my-2 my-md-5">
            <span className="copyright__text">
              ЧДГ Кристофър Робин &copy; {new Date().getFullYear()}
            </span>
            <span className="copyright__text">
              <a rel="noopener noreferrer" target="_blank" href="https://ralev.com/">
                  Лого дизайн
              </a>{' '}
              и <a rel="noopener noreferrer" target="_blank" href="https://ralev.com/">уеб дизайн</a> от{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                title="Logo and brand design agency"
                href="https://ralev.com/">
                Ralev.com
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Copyright
