import React from 'react'
import { compose, setDisplayName, mapProps } from 'recompose'
import { StaticQuery, graphql } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const HeaderNavbar = ({ items, listClassName, listItemClassName, onLinkClick, ...props }) => (
  <nav {...props}>
    {items.length && <ul className={`list-unstyled ${ listClassName }`}>
      {items.map(({ title, href }, idx) => (
        <li key={`${ idx }-title`} className={listItemClassName}>
          <AnchorLink href={`#${ href }`} onClick={() => onLinkClick()}>
            <span className="bg-link">{title}</span>
          </AnchorLink>
        </li>
      ))}
    </ul>}
  </nav>
)

HeaderNavbar.defaultProps = {
  items: [],
  listClassName: ``,
  listItemClassName: ``,
  onLinkClick: () => {},
}

// const HeaderNavbarGraphql = compose(
//   setDisplayName(`HeaderNavbar`),
//   mapProps(({ file: { yaml }, ...rootProps }) => {
//     // console.debug(`rootProps: `, rootProps)
//     const props = {
//       ...rootProps,
//       ...yaml,
//     }

//     return props
//   }),
// )(HeaderNavbar)

// export default HeaderNavbarGraphql
export const fragmentSettingsHeaderNavigation = graphql`
  fragment FragmentSettingsHeaderNavigation on SettingsYaml {
    navigation {
      title
      href
    }
  }
`

export default HeaderNavbar
