import React from 'react'
import { compose, lifecycle, withHandlers } from 'recompose'
import { debounce } from 'lodash'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import goToTopImg from '../../images/icon/to-top.png'

const GoToTop = compose(
  withHandlers({
    handleScroll: () => debounce(() => {
      const winHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

      const scrollTop = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop,
        window.pageYOffset,
      )

      try {
        document.body.classList.toggle(`back-to-top-visible`, scrollTop > winHeight)
      } catch (e) {}
    }, 200),
  }),
  lifecycle({
    componentDidMount () {
      if (document.body.classList) {
        window.addEventListener(`scroll`, this.props.handleScroll)
      } else {
        document.body.className += ` back-to-top-visible`
      }
    },

    componentWillUnmount () {
      if (document.body.classList) {
        window.removeEventListener(`scroll`, this.props.handleScroll)
      }
    },
  }),
)(
  () => (
    <div id="to-top">
      <AnchorLink href="#header">
        <img src={goToTopImg} alt="Към начало" />
      </AnchorLink>
    </div>
  )
)

export default GoToTop
