import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
// import Helmet from 'react-helmet'
import { enhanceWithPageContextProvider } from 'utils'
import { IntlProvider, addLocaleData } from 'react-intl'
import { lifecycle, compose } from 'recompose'
import Header from 'components/jobby/Header'
import Footer from 'components/jobby/Footer'
import BackToTop from 'components/jobby/GoToTop'

// import ModalQuestionForm from "components/jobby/ModalQuetionForm";
// import ModalBookingForm from "components/jobby/ModalBookingForm";

// import locales from '../../config/locales'
// import en from 'l10n/en.json'
import bg from 'l10n/bg.json'

// Locale data
// import enData from 'react-intl/locale-data/en'
// import bgData from 'react-intl/locale-data/bg'

import 'bootstrap/dist/css/bootstrap.css'
import 'hamburgers/dist/hamburgers.css'
import 'styles/jobby.css'
import 'styles/nunito-font.css'
import 'animate.css'
import 'icons'

// console.debug(`WOW: `, WOW)
// addLocaleData(bgData)

const l10n = {
  bg
}

const Layout = compose(
  enhanceWithPageContextProvider,

  lifecycle({
    componentDidMount: async () => {
      const WOW = await import(`wow.js`)

      const wow = new (WOW.default || WOW)({
        offset: 80,
        mobile: true,
        live: true,
      })

      wow.init()
      try {
        document.body.classList.add(`wow-ready`)
      } catch (e) {
        document.body.className += ` wow-ready`
      }
    }
  })
)(
  ({ children, pageContext }) => (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <IntlProvider locale={pageContext.lang} messages={l10n[pageContext.lang]}>
          <>
            <Header />

            {children}

            <Footer />

            <BackToTop />
          </>
          {/* <ModalQuestionForm />
          <ModalBookingForm /> */}
        </IntlProvider>
      )}
    />
  )
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
