import React from 'react'

const Icon = ({ id, size = `1em`, className, ...props }) => (
  <svg className={`d-inline-block ${ className }`} {...props} style={{ width: size, height: size }} dangerouslySetInnerHTML={{ __html: `<use xlink:href="#${ id }" fill="currentColor"></use>` }} />
)

Icon.defaultProps = {
  className: ``,
}

export default Icon
