/* global Event */

import React from 'react'
import HeaderTopContact from './HeaderTopContact'
import HeaderNavbar from './HeaderNavbar'
import HeaderMobile from './HeaderMobile'
import { compose, defaultProps, withState, withProps, lifecycle, setDisplayName, mapProps } from 'recompose'
import Headroom from 'react-headroom'
// import Logo from 'components/Logo'
import { StaticQuery, graphql } from 'gatsby'
import { defaultOnFail } from 'utils'
import { GatsbyImage } from "gatsby-plugin-image";
// import Icon from 'components/SvgSpriteIcon'
import styled from 'styled-components'

const HeadroomStyled = compose(
  withProps({
    disableInlineStyles: true,
  }),
)(
  Headroom
)

const LogoWrapper = styled.div`
  /* height: 90px; */
  /* height: 62px; */
  width: 200px;

  a {
    display: block;
  }

  /* .headroom--unpinned {
  } */
`

const Header = compose(
  withProps(() => ({
    headerTopRef: React.createRef(),
  })),

  withState(`headroomPinStart`, `setHeadroomPinStart`, 0),

  lifecycle({
    componentDidMount () {
      this.props.setHeadroomPinStart(parseInt(this.props.headerTopRef.current.offsetHeight, 10))

      if (process.env.NODE_ENV === `development`) {
        setTimeout(function () {
          window.dispatchEvent(new Event(`resize`))
        }, 500)
      }
    }
  })
)(
  ({
    // setHeaderTopRef,
    headerTopRef,
    facebookUrl,
    address,
    phone,
    navigation,
    logoFluid,
  }) => (
    <header id="header">
      <HeaderTopContact ref={headerTopRef} {...{
        facebookUrl,
        address,
        phone,
      }} />

      <HeadroomStyled pinStart={ headerTopRef.current ? headerTopRef.current.offsetHeight : 0 }>
        <div className="header header-1 d-none d-lg-block js-header-1">
          <div className="header__bar">
            <div className="container">
              <div className="header__content">
                <LogoWrapper className="logo">
                  <a href="#">
                    <GatsbyImage image={logoFluid} />
                  </a>
                </LogoWrapper>
                <div>
                  <HeaderNavbar items={navigation} className="header-navbar" listClassName="mb-0" listItemClassName="header-navbar__item" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <HeaderMobile navigation={navigation} logoFluid={logoFluid} />
      </HeadroomStyled>

    </header>
  )
)

const HeaderGraphql = compose(
  setDisplayName(`Header`),
  mapProps(
    (props) => {
      const {
        generalSettings: {
          json: { facebookUrl }
        },
        headerSettings: {
          yaml: { address, phone, navigation, logo }
        },
        ...rootProps
      } = props

      return {
        facebookUrl,
        address,
        phone,
        navigation,
        logoFluid: defaultOnFail(() => logo.sharp.gatsbyImageData, null),
        ...rootProps
      }
    }
  )
)(Header)

export default props => <StaticQuery
  query={graphql`{
  generalSettings: file(relativePath: {eq: "settings/general.json"}) {
    json: childSettingsJson {
      facebookUrl
    }
  }
  headerSettings: file(relativePath: {eq: "settings/header-footer.yml"}) {
    yaml: childSettingsYaml {
      address
      phone
      logo {
        sharp: childImageSharp {
          gatsbyImageData(
            width: 240
            quality: 80
            layout: CONSTRAINED
          )
        }
      }
      ...FragmentSettingsHeaderNavigation
    }
  }
}`}
  render={data => <HeaderGraphql {...props} {...data} />}
/>
