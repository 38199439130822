import React from 'react'
import Copyright from './Copyright'

const Footer = () => (
  <footer className="footer">

    <Copyright />
  </footer>
)

export default Footer
